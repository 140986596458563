import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cx from 'classnames';
import { retryInputOTP, setRequireOTP, userLogout } from 'src/screens/auth/redux/action';
import { SimplePageStyled } from 'src/components/styled';
import OtpInput from 'src/components/otpInput';
import { LabelLang } from 'src/lang/components';
import { OTP_RETRY, OTP_NUM_INPUTS } from 'src/resources/constants/app';
import { getRecaptcha } from 'src/hocs/hocRecaptcha';
import pinCode from '../img/pin-code.svg';
import styles from './style.scss';

const getIntlKey = name => `me.twoFa.input2Fa.${name}`;

const TwoFactorFormStyled = styled.div`
  padding: 20px 20px 20px !important;
  text-align: center;
  font-size: 14px;
  .title {
    color: #F5A623;
    margin: 40px 0 10px;
  }
  .desc {
    color: #0A0A0A;
    margin-bottom: 20px;
  }
  .errorMessage {
    font-size: 12px;
  }
  
  .inputStyle {
    width: 2.5rem!important;
    height: 3.5rem;
    line-height: 3.5rem;
    border-radius: 0px!important;
    padding: 0;
    border: none;
    border-bottom: 1px solid #CDCDCD;
    font-size: 2rem;
    font-weight: 500;
    background: none;
    &:hover, &:focus {
      outline: none;
      border: none;
      border-bottom: 1px solid #CDCDCD;
    }
  }
  
  .cantAccess {
    opacity: 0.39;
    color: #0A0A0A;
    margin-top: 120px;
    cursor: pointer;
  }
`;

class TwoFactorForm extends React.PureComponent {
  otpInputRef;
  formRef;
  timeout;

  constructor(props) {
    super(props);
    this.state = { };

    this.otpInputRef = React.createRef();
    this.formRef = React.createRef();
  }

  componentWillUnmount() {
    this.handleRejectInput();
  }

  handleChangeOtp = async (values) => {
    const { loginCallBack, userLogout, callback, numRetryOTP } = this.props;
    const captchaToken = await getRecaptcha('GetOtp');
    if (values.filter(v => !!v).length === OTP_NUM_INPUTS) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const sendCode = values.join('').trim();
        if (sendCode.length >= OTP_NUM_INPUTS) {
          this.otpInputRef.current.reset();
          if (loginCallBack) {
            loginCallBack(sendCode, captchaToken);
          }

          if (callback) {
            callback(sendCode);
          }

          if (numRetryOTP > OTP_RETRY) {
            userLogout();
          }
        }
      }, 500);
    }
  };

  handleRejectInput = () => {
    const { setRequireOTP, onRequireOTP } = this.props;

    setRequireOTP(false, null);
    retryInputOTP(false);

    if (onRequireOTP) {
      onRequireOTP(false);
    }
  };

  render() {
    const { numRetryOTP, callback, activeInput } = this.props;
    return (
      <SimplePageStyled>
        <p className={cx('welcomeTitle text-center bold')}>
          <LabelLang id={getIntlKey('popupTitle')} />
        </p>
        {/*<div className={cx('logoForm')}>
          <img src={logo} alt="MyConstant register" />
        </div>*/}
        <TwoFactorFormStyled className="wrapper form">
          <img src={pinCode} alt="pin code" />
          <div className="title bold"><LabelLang id={getIntlKey('newTitle')} /></div>
          <div className="desc"><LabelLang id={getIntlKey('newInputCode')} /></div>
          <div ref={this.formRef}>
            <OtpInput
              containerStyle={styles.otpContainer}
              inputStyle="inputStyle"
              onChange={this.handleChangeOtp}
              numInputs={OTP_NUM_INPUTS}
              isInputNum
              shouldAutoFocus
              ref={this.otpInputRef}
              activeInput={activeInput}
            />
            {numRetryOTP > 1 && <small className="errorMessage text-danger"><LabelLang id="error.serverCode.otpNotMatched" /></small>}
          </div>
          <div
            className="cantAccess"
            onClick={() => {
              callback && callback(null);
              this.handleRejectInput();
            }}
          >
            <LabelLang id={getIntlKey('cantAccess')} />
          </div>
        </TwoFactorFormStyled>
      </SimplePageStyled>
    );
  }
}

const mapState = state => ({
  loginCallBack: state.auth.loginCallBack,
  numRetryOTP: state.auth.numRetryOTP,
});
const mapDispatch = {
  setRequireOTP,
  retryInputOTP,
  userLogout
};

export default connect(mapState, mapDispatch)(TwoFactorForm);
